import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subscription, Subject } from 'rxjs';

import { SlurrySource, MATERIAL_TYPE, IFACT_CONSTANTS } from 'libs/constants';
import { PDropDownModel, ColumnDefinition, ErrorMessageModel, ConfirmDialogService } from 'libs/ui';
import { IMaterialModel, IMaterialTypeModel, ISlurryType, MaterialMappingModel, ISAPMaterialModel, Job } from 'libs/models';
import { EventHubService, MaterialService } from 'libs/shared/services';
import { MaterialNumberAssignedFlag } from '../../../shared/constant/slurry-source';
import { EditJobAdapter } from '../../../edit-job/adapters';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'fluid-materials',
  templateUrl: './fluid-materials.component.html',
  styleUrls: ['./fluid-materials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FluidMaterialsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() fluidMaterials: UntypedFormGroup[];
  @Input() header: string;
  @Input() slurrySourceId: any;
  @Input() index: number;
  @Input() fluidType: string;
  @Input() slurryTypeId: string;
  @Input() canEdit: boolean;
  @Input() concentrationUnitList: any[] = [];
  @Input() job: Job;
  @Output() onAddSup = new EventEmitter<any>();
  @Output() supplementalRemoved = new EventEmitter<{ index: number }>();
  @Output() onSAPMaterialChange = new EventEmitter();

  isExpanded: boolean;
  isCement: boolean = null;
  materalOptions: IMaterialModel[][] = [];
  materialTypeData: PDropDownModel[];
  lastMaterialValue: number;
  slurryTypes: ISlurryType[];
  materialTypes: IMaterialTypeModel[];
  sapMaterialOptionsHash: any = {};
  materialMappings: MaterialMappingModel[];
  loadingSubscription: Subscription;
  SlurrySource = SlurrySource;
  MATERIAL_TYPE = MATERIAL_TYPE;
  isSupplemental: boolean;
  isMudSlurryTypeOfSupMat: boolean;
  filterConcenUnitSupple = [];
  subcriptions: Subscription;
  rowIndex = null;

  private readonly _destroy$ = new Subject();

  materialColumns: ColumnDefinition[] = [
    new ColumnDefinition('name', 'Material Name'),
    new ColumnDefinition('materialId', 'Material Id'),
  ];
  errorMessages = {
    materialName: [
      new ErrorMessageModel('notExisted', 'Material does not exist. Please input another value.'),
      new ErrorMessageModel('required', 'Material is required field.'),
      new ErrorMessageModel('existed', 'Duplicate Mapping for SAP Material.  Please enter iFacts Material.')
    ],
    sapMaterialNumber: [
      new ErrorMessageModel('notExisted', 'SAP Material does not exist. Please input another value.'),
    ],
    concentration: [
      new ErrorMessageModel('required', 'Concentration is required field.')
    ],
    concentrationUnit: [
      new ErrorMessageModel('required', 'Concentration Unit is required field.')
    ]
  };

  constructor(
    private materialService: MaterialService,
    private ref: ChangeDetectorRef,
    private confirmDialogService: ConfirmDialogService,
    protected editJobAdapter: EditJobAdapter,
    public eventHub: EventHubService
  ) {
    const self = this;
    
    self.editJobAdapter.updateSAPMaterialFromPump$.subscribe(_ => {
      let fluidIndex = null;

      if (this.fluidType === MATERIAL_TYPE.SUPPLEMENTAL && _.isSupplemental) {
        fluidIndex = this.index - 1;
      } else {
        fluidIndex = _.isBlend && this.index < 0 ? Math.abs(this.index) - 1 : null;

        if (fluidIndex == null) {
          fluidIndex = !_.isBlend && this.index > 0 ? this.index - 1 : null;
        }
      }

      if (fluidIndex == null) return;

      const slurry = self.editJobAdapter.fluidFormArray.controls[fluidIndex] as UntypedFormGroup;
      if (slurry == null) return;
      let slurryId = slurry.controls.id.value ? slurry.controls.id.value : slurry.controls.slurryIdHDF.value;
      if (!slurryId) {
        slurryId = `${slurry.controls.slurryId.value}/${slurry.controls.slurryNo.value}`;
      }
      if (!slurryId || slurryId !== _.sullryId) return;
      const materialForm = self.fluidMaterials[_.materialIndex] as UntypedFormGroup;
      if (materialForm) {
        materialForm.controls.sapMaterialNumber.setValue(_.sapMaterialNumber);
        materialForm.controls.sapMaterialNumber.setErrors(null);
        if (materialForm.controls.materialNumberAssignedFlag) {
          materialForm.controls.materialNumberAssignedFlag.setValue(MaterialNumberAssignedFlag.Manual);
        }
      }

    });
  }

  private get fluidIndex(): number{
    return Math.abs(this.index) - 1;
  }

  checkDuplicateMaterialWhenInit() {
    var fluidMaterialsMissingNames = []
    var sapNumbersMissingNames = []

    let index: number = -1;
    this.fluidMaterials.forEach((fluidMaterialDetail) => {
      const { controls: { sapMaterialNumber: { value: sapMaterialNumber }} } = fluidMaterialDetail;
      this._validateAfterCheckSAPDuplicate(fluidMaterialDetail,{materialNumber: sapMaterialNumber}, true, ++index);
    });
  }

  ngOnInit(): void {
    this.subcriptions = (this.editJobAdapter.availableSapMappingsGroupChanged$.subscribe((data) => {
      if (!this.job.editMode) {
        setTimeout(() => {
          this.checkDuplicateMaterialWhenInit();
        })
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { fluidMaterials, fluidType, slurryTypeId } = changes;
    if (fluidMaterials && fluidMaterials.currentValue && fluidMaterials.currentValue.length > 0) {

      ///REMOVE IT!!
      setTimeout(() => {
        let index: number = -1;
        this.fluidMaterials.forEach((fluidMaterialDetail) => {
          const { controls: { materialName: { value: materialName }, materialId: { value: materialId }, sapMaterialNumber: { value: sapMaterialNumber, errors: sapMaterialNumberErrors } } } = fluidMaterialDetail;

          if (this.slurrySourceId === SlurrySource.HDFFluid && (materialName === '' || materialName === null)) {
            fluidMaterialDetail.controls.materialName.setErrors({ 'notExisted': true });
            fluidMaterialDetail.controls.materialName.markAsDirty();

            if (fluidMaterialDetail.controls.notExistedSAPMaterial.value) {
              fluidMaterialDetail.controls.sapMaterialNumber.setErrors({ 'notExisted': true });
              fluidMaterialDetail.controls.sapMaterialNumber.markAsDirty();
            }
          }
        });
      });
    }

    if (fluidType && fluidType.currentValue) {
      this.isSupplemental = this.fluidType === MATERIAL_TYPE.SUPPLEMENTAL;
    }
    if (slurryTypeId && slurryTypeId.currentValue) {

      this.editJobAdapter.slurryTypeData$.pipe(take(1), takeUntil(this._destroy$))
        .subscribe(value => {
          const slurryType = value.find(x => x.value === this.slurryTypeId);

          if (slurryType) {
            this.isMudSlurryTypeOfSupMat = slurryType && slurryType.label === 'Mud';
            this.filterConcentrationUnitSupple(slurryType.label);
          }
        });
    }
  }

  public ngOnDestroy() {

    this._destroy$.next();
    this._destroy$.complete();
    this.subcriptions.unsubscribe();
  }

  onLazyLoadMaterial(keyword: string, index: number) {
    this.materialService.searchMaterialByName(keyword).subscribe(res => {
      this.materalOptions[index] = res;
      // triggering change detection manually.
      this.ref.markForCheck();
    });
  }

  _onClearMaterial(fluidMaterialDetail: UntypedFormGroup, material: IMaterialModel, rowIndex: number) {
    fluidMaterialDetail.controls.ifactMaterialAssignedFlag.setValue(MaterialNumberAssignedFlag.Manual);
  }

  onChangeMaterial(fluidMaterialDetail: UntypedFormGroup, material: IMaterialModel, rowIndex: number) {
    if (material) {
      const { materialId } = material;
      const { controls: { sapMaterialNumber: { value: sapMaterialNumber, errors: sapMaterialNumberErrors } } } = fluidMaterialDetail;
      if (this.lastMaterialValue !== materialId && materialId) {
        fluidMaterialDetail.patchValue({
          materialId: materialId,
          materialName: material.name,
          ifactMaterialAssignedFlag: MaterialNumberAssignedFlag.Manual
        }, { emitEvent: false });
        this.editJobAdapter.fillMaterial(fluidMaterialDetail, this.fluidIndex, false);

        this._notifySAPChange(fluidMaterialDetail, {
          materialNumber: sapMaterialNumber,
          materialName: material.name,
        }, rowIndex, false);
        
        // triggering change detection manually.
        this.ref.markForCheck();
      }
    } else {
      if (this.slurrySourceId === SlurrySource.HDFFluid) {
        fluidMaterialDetail.controls.materialName.setErrors({ 'notExisted': true });
        fluidMaterialDetail.controls.materialName.markAsDirty();

        return;
      }
    }
    this.editJobAdapter.updateSlurryData(fluidMaterialDetail);
  }

  onChangeSAP(fg: UntypedFormGroup, sap: ISAPMaterialModel, rowIndex: number) {
    if (sap) {
      fg.controls.materialNumberAssignedFlag.setValue(MaterialNumberAssignedFlag.Manual);
      this.editJobAdapter.fillMaterial(fg, this.fluidIndex, true);
      this._notifySAPChange(fg, sap, rowIndex);
      this.onSAPMaterialChange.emit();
    }
  }

  onSAPDuplicated(selectedId: any, sapMaterialNumber: any, isSAPChanged: boolean = false, fluidMaterialDetail: UntypedFormGroup, index: number = -1) {
    if (!isSAPChanged) return false;

    const value = this.editJobAdapter.availableSapMappings; //TODO: it should be initialized
    const materialExistsMultipleTime = value?.filter(fluidMaterial => !!fluidMaterial.sapMaterialNumber && fluidMaterial.sapMaterialNumber === sapMaterialNumber);

   if (materialExistsMultipleTime && materialExistsMultipleTime.length > 1 && index >= 0) {
      let materials: IMaterialModel[] = materialExistsMultipleTime.map((value) => {return { id: value.id, materialId : value.materialId as any, name: value.iFactMaterialName}});
      this.materalOptions[index] = materials;
    }

    // if sap materail found multiple time in material management then display error message.
    // user must populate ifact material differently
    return (materialExistsMultipleTime && materialExistsMultipleTime.length > 1)
  }

  _validateAfterCheckSAPDuplicate(fg: UntypedFormGroup, sap , isSap = true, index: number = -1){
    const { controls: { materialId: { value: materialId } } } = fg;
    const { materialNumber: sapMaterialNumber } = sap;
    const sapDuplicated = this.onSAPDuplicated(materialId, sapMaterialNumber, isSap, fg, index);
    this._setValidateMaterialName(fg, sapDuplicated, isSap);
  }

  _setValidateMaterialName(fg: UntypedFormGroup, sapDuplicated, isSap = true){
    if (sapDuplicated || fg.controls.materialName.errors?.existed) {
      if(this.job.createMethod == 'hdf') {
      isSap && fg.controls.materialName.setValue("");
      fg.controls.materialName.setErrors({ 'existed': true });
      fg.controls.materialName.markAsDirty();
      }
    } else {
      if (fg.controls.notExistedSAPMaterial?.value) {
        if (fg.controls.notExistedSAPMaterial.value && isSap && fg.controls.materialName.value)
        {
          fg.controls.sapMaterialNumber.setErrors(null);
        } else
        {
          fg.controls.sapMaterialNumber.setErrors({ 'notExisted': true });
        }
        fg.controls.sapMaterialNumber.markAsDirty();
      }

      if (fg.controls.materialName?.value === "" || fg.controls.materialName?.value === null) {
        fg.controls.materialName.setErrors({ 'notExisted': true });
        fg.controls.materialName.markAsDirty();

      }
    }
  }

  _notifySAPChange(fg: UntypedFormGroup, sap: ISAPMaterialModel, rowIndex: number, isSap = true) {
    const { materialNumber: sapMaterialNumber } = sap;
    const { errors: materialNumberErrors } = fg.controls.sapMaterialNumber;

    this._validateAfterCheckSAPDuplicate(fg, sap, isSap);

    this.editJobAdapter.updateSourceSAPMaterialMapping(this.fluidIndex, rowIndex, isSap, this.index < 0);
  }

  onClearSAP(fg: UntypedFormGroup, sap: ISAPMaterialModel, rowIndex: number) {
    if (!sap) {
      sap = new ISAPMaterialModel;
    }
    fg.controls.sapMaterialNumber.setValue(null);
    fg.controls.materialNumberAssignedFlag.setValue(MaterialNumberAssignedFlag.Manual);
    this._notifySAPChange(fg, sap, rowIndex);
    this.onSAPMaterialChange.emit();
  }

  markAsTouched() {
    this.ref.markForCheck();
    this.fluidMaterials.forEach(fluidMaterial => {

      (<any>Object).values(fluidMaterial.controls).forEach(control => {
        control.markAsTouched();
      });
    });
  }

  removeSupplemental(rowIndex, fluidMaterialDetail) {
    setTimeout(_ => {
      this.eventHub.onRemoveSupplemental$.next({ 
        name: fluidMaterialDetail.materialName,
        type: fluidMaterialDetail.materialType,
        concentration: fluidMaterialDetail.concentration,
        concentrationUnit: fluidMaterialDetail.concentrationUnit,
        sapMaterialNumber: fluidMaterialDetail.sapMaterialNumber,
        rowIndex: rowIndex 
      });
    }, 0);
    this.eventHub.onRemoveSupplementalModalSuccess$.subscribe(x => {
      if (x.found) {
        this.supplementalRemoved.emit(x.rowIndex);
      }

      else if (x.notFound) {
        this.confirmDialogService.show({
          message: `Are you sure to remove this Supplemental Materials?`,
          header: 'Confirmation',
          accept: () => {
            this.supplementalRemoved.emit(x.rowIndex);
          },
          reject: () => {
          },
          acceptLabel: 'Yes',
          rejectLabel: 'No'
        });
      }
    });
  }

  addNewSup() {
    this.onAddSup.emit(this.index);
  }

  updateFluidSupple() {
    this.editJobAdapter.updateFluids$();
  }

  updateUnitConcenSupple(event, index) {
    const unit = this.filterConcenUnitSupple.find(x => x.value === event.value);
    if (unit) {
      this.fluidMaterials[index].controls.concentrationUnit.setValue(unit.label);
    } else {
      this.fluidMaterials[index].controls.concentrationUnit.setValue(null);
    }
    this.updateFluidSupple();
  }

  filterConcentrationUnitSupple(slurryTypeValue) {
    const cementFileter = x => !!IFACT_CONSTANTS.CEMENT_SLURRY_TYPE.find(
      k => k === (x.label || '').toLowerCase().replace(/\s+/g, '')
    );
    const spacerFilter = x => !!IFACT_CONSTANTS.SPACER_SLURRY_TYPE.find(
      k => k === (x.label || '').toLowerCase().replace(/\s+/g, '')
    );

    const filterType = slurryTypeValue && slurryTypeValue.includes('Cement') ? cementFileter : spacerFilter;
    // TMP
    if (this.concentrationUnitList) {
      this.filterConcenUnitSupple = this.concentrationUnitList.filter(x => filterType(x));
    }
  }
}
