import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { FluidStateManager } from '../../state/fluid-state-manager';
import { StageStateManager } from '../../state/stage-state-manager';
import { Job } from 'libs/models';
import { PumpScheduleStageTabs } from '../../../shared/constant/pump-schedule-stage-tabs';
import { PumpScheduleService } from '../../../pump-schedule/services/pump-schedule.service';

@Component({
    selector: 'stage',
    templateUrl: './stage.component.html',
    styleUrls: ['./stage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageComponent implements OnInit, OnDestroy {

    openStageTabIndex: number;
    private _toggleSrc = new BehaviorSubject<boolean>(null);
    private _isExpanded$: Observable<boolean>;

    @ViewChild("stage") stage: ElementRef;

    @Output() onStageAlign = new EventEmitter<any>();

    @Input()
    public stageState: StageStateManager;

    @Input()
    public slurryData: Observable<any>;

    @Input()
    public readonly refreshForm: Observable<any>;

    @Input()
    public isPumpDefault: boolean;

    @Input() index: number;

    @Input()
    public job: Job;

    constructor(
        private cd: ChangeDetectorRef,
        private pumpScheduleService: PumpScheduleService
    ) {
        
    }

    public get fluidState(): FluidStateManager {

        return this.stageState.fluidState;
    }

    public get isPlug$(): Observable<boolean> {

        return this.stageState.isPlug$;
    }

    public get isFluid$(): Observable<boolean> {

        return this.stageState.isFluid$;
    }
    
    public get isIFactsFluid$(): Observable<boolean> {

        return this.stageState.isIFactsFluid$;
    }

    public get number$(): Observable<number> {

        return this.stageState.number$;
    }

    public get color$(): Observable<string> {

        return this.stageState.color$;
    }

    public get isExpandable$(): Observable<boolean> {
        return this.isPlug$
            .pipe(
                map(isPlug => !isPlug)
            );
    }

    public get isExpanded$(): Observable<boolean> {

        return this._isExpanded$;
    }

    public get isInvalid(): boolean {

        return !this.stageState.form.disabled
            && this.stageState.form.touched
            && this.stageState.form.invalid;
    }

    public ngOnInit(): void {
        this._setStageToggle();
        if (this.refreshForm != null) {
            this.refreshForm.subscribe(() => {
                this.cd.detectChanges();
            });
        }
    }

    public ngOnDestroy(): void {

        this._toggleSrc.complete();
    }

    public toggle(): void {
        this._toggleSrc.next(!this._toggleSrc.value);
    }

    private _setStageToggle(): void {

        // In current implementation stages are intially in collapsed state on Schedule Edit View.
        // There are 2 sources of stage expansion:
        //   1. User clicks on expansion button (toggles expanded / collapsed state)
        //   2. User changes stage type from not plug to plug or vice versa. In this case
        //      we need to reset toggle clicks. E.g. when stage is plug and user clicks on it,
        //      then toggle last value becomes 'expanded' (however no visually seen).

        // We are combining 2 sources of expansion / collapse
        this._isExpanded$ = combineLatest([

            // Changes of stage type
            this.isPlug$
                .pipe(
                    tap(isPlug => {

                        // Here we reset toggle state for all cases when stage type (isPlug) changes
                        // except initial type emission.
                        // Initially (when toggleSrc value is null) stages of all types are in collapsed state.
                        if (this._toggleSrc.value !== null) {

                            // Make toggle think it is in expanded state now when stage is not plug
                            // and in collapsed state when stage is plug.
                            this._toggleSrc.next(!isPlug);
                        }

                        // But all new stages (without stage type selected yet) start in collapsed state.
                        if (this.stageState.noType) {

                            this._toggleSrc.next(false);
                        }
                    })
                ),

            // 'Clicks' on expand / collapse button. 
            this._toggleSrc.asObservable()
        ])
            .pipe(
                map(([isPlug, expand]) => {
                    if (!isPlug) {
                        if (this.stage) {
                            this.onStageAlign.emit({ stage: this.stage.nativeElement, isAlignNearest: expand && this.openStageTabIndex === PumpScheduleStageTabs.FluidDetails });
                        }
                    }
                    return !isPlug && expand;
                }),
                shareReplay()
            );
    }

    onStageTabSelected(event: any) {
        this.onStageAlign.emit({ stage: this.stage?.nativeElement, isAlignNearest: event?.index === PumpScheduleStageTabs.FluidDetails });
    }
}
