import { Input, ViewChild, ChangeDetectorRef, Directive } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { tap, debounceTime, switchMap, skip, map } from 'rxjs/operators';
import { Subscription, Subject, of, Observable } from 'rxjs';
import { isEmpty } from 'libs/helpers/lodash-helper';
import { AutoComplete } from 'primeng/autocomplete';
import { EventHubService, MemberSearchService, SessionService } from 'libs/shared/services';
import { AdUser, Job } from 'libs/models';
import { HandOver, SelectedFileModel } from '../../../control-point/models';
import { ControlPointService2 } from 'libs/shared/services';
import { HttpCommanderService, ApplicationStateService } from 'libs/shared/services';
import { ConfirmDialogService } from 'libs/ui';
import { CommunicationType } from '../../../control-point/models/communication-type.model';
import { FileModel } from '../../../control-point/models/';
import { ControlPointState, VidaFileType } from '../../../shared/constant';
import { IJobInformation } from '../../models/breadcrumb.model';
import { IOperationResult } from '../../../shared/constant/operation-result';
import { environment } from 'libs/environment';
import * as jstz from 'jstz';

@Directive()
export class HandOverComponentBase {

  @Input() jobId: string;
  @Input() wellId: string;
  @Input() canEdit: string;
  @Input() controlPointId: string;
  @Input() job: Job;
  @Input() jobInfor: IJobInformation;
  @ViewChild('supervisorAutoComplete') supervisorAutoComplete: AutoComplete;
  handOverFg: UntypedFormGroup;
  handOver: HandOver;
  _isSearching: boolean = false;
  _showAutoCompleteError: boolean = false;
  members: AdUser[];
  result$ = new Subject<string>();
  communicationTypes: CommunicationType[] = [];
  handoverFile: FileModel = null;
  
  CP1NotCompletedOrSubmitted: boolean;
  CP1Approved: boolean;
  handOverDisabled: boolean = false;
  handoverGroup: UntypedFormGroup;

  handoverFileType: VidaFileType = VidaFileType.Handover;
  loadingSubscription: Subscription;
  hasRiskAssessmentFileDownloaded: boolean = false;
  public isCallSheetGenerating: boolean = false;
  applyHandOverValidation: boolean = false;

  protected subscription = new Subscription();

  get destinationFolderPath() : string {
    return `VIDA/${this.wellId}/${this.jobId}/Internal/Control Points/Handover`;
  }

  constructor(
    protected fb: UntypedFormBuilder,
    protected membersSearchService: MemberSearchService,
    protected controlPointService: ControlPointService2,
    protected HCService: HttpCommanderService,
    protected confirmDialogService: ConfirmDialogService,
    protected applicationStateService: ApplicationStateService,
    protected sessionService: SessionService,
    protected eventHubService: EventHubService,
    protected cdr: ChangeDetectorRef
  ) { }

  deleteFile() {
    this.confirmDialogService.show({
      message: `Are you sure you want to delete this file?`,
      header: 'Delete Confirmation',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        const filePathInfo = this.splitToFileNameAndFolderPath(this.handoverFile.virtualRelativeFilePath);
        this.loadingSubscription = this.HCService.deletePhysicalFileByHcHandler(filePathInfo.folderPath, filePathInfo.fileName, this.handoverFileType)
          .subscribe((data: any) => {
            if (data.statusCode === 200) {
              this.handoverFile = null;
              this.cdr.markForCheck();
            }
            this.applicationStateService.cp2HandoverFileChanged$.next('deleted');
          });
      },
      reject: () => { }
    });
  }

  initAutoComplete() {
    this.subscription.add(
      this.result$.pipe(
        tap(() => this._isSearching = true),
        debounceTime(500),
        switchMap((value: string) => this.membersSearchService.search(value))
      ).subscribe(
        (data: AdUser[]) => {
          this._isSearching = false;
          this._showAutoCompleteError = (data.length <= 0) ? true : false;
          this.members = data;
          this.cdr.markForCheck();
        },
        error => {
          this._isSearching = false;
          this._showAutoCompleteError = true;
        }
      ));
  }

  getHandOver(controlPointStatus: ControlPointState = null, ho: HandOver = null) {
    this.controlPointService.getCommunicationTypes()?.subscribe(res => {
      if (res) {
        this.communicationTypes = res.map(type => Object.assign(new CommunicationType, type));

        if (ho != null) {
          this.handOver = ho;
          this.cdr.markForCheck();
          return;
        }

        this.controlPointService.getHandOver(this.controlPointId).subscribe(result => {
          if (result) {
            const temp = Object.assign(new HandOver, result);
            this.handOver = temp;
            if (!isEmpty(this.handOver.supervisor)) {
              this.handOver.supervisor.display = `${this.handOver.supervisor.firstName} ${this.handOver.supervisor.lastName} (${this.handOver.supervisor.networkId})`;
            } else if(!isEmpty(this.handOver.networkId)) {
              this.handOver.supervisor = new AdUser();
              this.handOver.supervisor.display = this.handOver.networkId
            }
            if (this.handOver.communicationTypeId) {
              this.handOver.communicationType = this.communicationTypes.find(type => type.id === this.handOver.communicationTypeId);
            }
          } else {
            this.handOver = new HandOver();
          }
          this.handOverFg = this.createFormGroup(this.handOver, controlPointStatus);

          this.subscription.add(
            this.handOverFg.get('communicationType').valueChanges.pipe(skip(1)).subscribe(type => {
              const dateTimeControl = this.handOverFg.get('communicationDateTime');
              if (!dateTimeControl.value) {
                dateTimeControl.setValue(new Date());
              }
            })
          );

          this.cdr.markForCheck();
        });
      }
    });
  }

  saveHandOver(): Observable<any> {
    if (this.handOverFg) {
      this.handOver = this.handOverFg.value;
      if (this.handOverFg.value.communicationType) {
        this.handOver.communicationTypeId = this.handOverFg.value.communicationType.id;
      } else {
        this.handOver.communicationTypeId = null;
      }
      this.handOver.controlPointId = this.controlPointId;
      return this.controlPointService.saveHandOver(this.handOver).pipe(
        tap((data: any) => {
          if (!this.handOver.handoverId) {
            this.getHandOver();
          }
        }));
    } else {
      return of(true);
    }
  }

  onComplete(result: string) {
    this.result$.next(result);
  }

  clearAutoCompleteInput() {
    this._showAutoCompleteError = false;
    this.supervisorAutoComplete.value = '';
  }

  clearSelectedSupervisor() {
    this.handOverFg.controls.supervisor.reset();
  }

  onClearDateTime() {
    this.handOverFg.controls.communicationDateTime.setValue(null);
  }

  createFormGroup(handOver: HandOver, controlPointStatus: ControlPointState = null): UntypedFormGroup {    
    this.handoverGroup = this.fb.group({
      handoverId: [
        {
          value: handOver.handoverId,
          disabled: false
        }
      ],
      supervisor: [
        {
          value: handOver.supervisor ? handOver.supervisor : new AdUser(),
          disabled: controlPointStatus === ControlPointState.Completed
        }
      ],
      communicationDateTime: [
        {
          value: handOver.communicationDateTime ? handOver.communicationDateTime : null,
          disabled: controlPointStatus === ControlPointState.Completed
        }
      ],
      communicationType: [
        {
          value: handOver.communicationType,
          disabled: controlPointStatus === ControlPointState.Completed
        }
      ],
      communicationTypeId: [
        {
          value: handOver.communicationTypeId ? handOver.communicationTypeId : handOver.communicationType.id,
          disabled: controlPointStatus === ControlPointState.Completed
        }
      ],
      comment: [
        {
          value: handOver.comment,
          disabled: controlPointStatus === ControlPointState.Completed
        }
      ],
      jobId: [
        {
          value: this.jobId,
          disabled: false
        }
      ],
      controlPointId: [
        {
          value: handOver.controlPointId ? handOver.controlPointId : this.controlPointId,
          disabled: false
        }
      ]
    });

    // Enable/Disable Form should be done in setTimeout due to bug in Angular
    // https://github.com/angular/angular/issues/22556
    setTimeout(() => {
        if (controlPointStatus === ControlPointState.Completed)
        {
          this.handOverDisabled = true;
          this.handoverGroup?.disable();
        }
        else if (this.CP1Approved) //CP1 is in Approved state should be editable CP2
        {
          if (this.handOverDisabled === false)
            this.handoverGroup?.enable();
        }
        else if (this.CP1NotCompletedOrSubmitted)
          {
            this.handOverDisabled = true;
            
            this.handoverGroup.controls['comment'].disable();
            this.handoverGroup.controls['communicationDateTime'].disable();
            this.handoverGroup.controls['communicationType'].disable();
            this.handoverGroup.controls['communicationTypeId'].disable();
          }
        else {
          if (this.handOverDisabled === false)
            this.handoverGroup?.enable();
            }
    });
    return this.handoverGroup;
  }

  checkValidateSubmitHandover(): boolean {
    let validSubmitHandover = true;    
    const handOverFormValue = this.handOverFg ? this.handOverFg.value : null;

    if (handOverFormValue === null) 
      validSubmitHandover = false;
   
    return validSubmitHandover;
  }

  
  checkValidateHandover(): boolean {
    let validHandover = true;
    const handOverFormValue = this.handOverFg ? this.handOverFg.value : null;

    if (handOverFormValue === null) {
      validHandover = false;
    } 

    if (!this.checkRequiredFields()) {
      validHandover = false;
    }

    return validHandover;
  }

  checkRequiredFields(): boolean{
    const handOverFormValue = this.handOverFg ? this.handOverFg.value : null;
    if (handOverFormValue === null) {
      return  false;
    }

    if (this.applyHandOverValidation) {
      return (
          handOverFormValue.supervisor?.networkId
          && handOverFormValue.communicationType?.id !== null 
          && handOverFormValue.communicationType?.name !== null
          && handOverFormValue.communicationDateTime != null
      );
    }
    else { 
      return false;
    }
  } 

  //This is called by a button in CP2
  generateControlPoint() {
    this.hasRiskAssessmentFileDownloaded = false;

    const reduced = this.controlPointService.shouldReduce(this.job);

    this.controlPointService.generateControlPoint(this.jobId, { filename: this.getCPFileName, reduced });
  }

  generateLoadSheetCalculator() {
    this.controlPointService.generateLoadSheetCalculator(this.jobId);
  }

  setCallSheetGenerate(generate: boolean) {
    this.isCallSheetGenerating = generate;
    document.body.style.cursor = generate ? 'progress' : 'unset';
  }

  generateCallSheet() {
    this.setCallSheetGenerate(true);

    const destinationFolderPathJobProgram = `VIDA/${this.job.wellId}/${this.jobId}/Reports/Cement Program`;
    const destinationFolderPathCallSheet = `VIDA/${this.job.wellId}/${this.jobId}/Call Sheet`;
    const destinationFolderPathRisk = `VIDA/${this.job.wellId}/${this.jobId}/Risk Assessment`;
    const destinationFolderPathJSQA = `VIDA/${this.job.wellId}/${this.jobId}/Internal/Control Points/JSQA`;
    const destinationFolderPathJSA = `VIDA/${this.job.wellId}/${this.jobId}/Internal/Control Points/JSA`;
    const destinationFolderPathEFTD = `VIDA/${this.job.wellId}/${this.jobId}/Internal/Control Points/Equipment Function Test`;
    const destinationFolderPathHandover = `VIDA/${this.job.wellId}/${this.jobId}/Internal/Control Points/Handover`;
    const destinationFolderPathMaterialLoadSheet = `VIDA/${this.job.wellId}/${this.jobId}/Internal/Control Points/Material Load Sheet`;
    const reportName = "Call Sheet";
    const fileName = this.getCallSheetFileName;
    const timezone = jstz.determine();

    const payload = {
      wellId: this.job.wellId,
      jobId: this.jobId,
      jobUrl: `${window.location.origin}/job-simple/${this.jobId}`,
      templatePath: `global-templates/${reportName}.docx`,
      templateTypeName: reportName,
      username: this.sessionService.user.userName,
      fileName: fileName.trim(),
      virtualRelativeFolderPathJobProgram: destinationFolderPathJobProgram,
      virtualRelativeFolderPathCallSheet: destinationFolderPathCallSheet,
      virtualRelativeFolderPathRiskAssesssment: destinationFolderPathRisk,
      virtualRelativeFolderJSQA: destinationFolderPathJSQA,
      virtualRelativeFolderJSA: destinationFolderPathJSA,
      virtualRelativeFolderEFTD: destinationFolderPathEFTD,
      virtualRelativeFolderHandover: destinationFolderPathHandover,
      virtualRelativeFolderPathMaterialLoadSheet: destinationFolderPathMaterialLoadSheet,
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZoneName: timezone ? timezone.name() : null
    };

    this.loadingSubscription = this.HCService.generateReport(payload).pipe(
      map((result: IOperationResult) => {
        if (!result?.success) {
          this.setCallSheetGenerate(false);
          return '';
        }
        return `${environment.httpCommanderUrl}/api/file/download?action=download&file=VIDA/${this.job.wellId}/${this.job.id}/${result.message}`;
      })
    ).subscribe(path => {
      if (!path) {
        this.setCallSheetGenerate(false);
        return;
      }
      this.eventHubService.onCallSheetGenerated$.next();
      const resultFileName = path.split('/').pop();
      this.controlPointService.callSheetDownload(path, resultFileName);
      this.setCallSheetGenerate(false);
    });
  }

  getFile(postLoadHandler: any = null) {
    this.loadingSubscription = this.HCService.getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.Handover)
      .subscribe((data) => {
        const { statusCode, result } = data;
        if (statusCode === 200) {
          this.setHandoverFile(result);
        }
        if (postLoadHandler) {
          postLoadHandler(data);
        }

        this.cdr.markForCheck();
      });
  }

  protected setHandoverFile(fileList: any[]) {
    const files: SelectedFileModel[] = fileList.map(x => {
      return Object.assign(new FileModel, x);
    });
    this.handoverFile = (files != null) ? files.find(f => f.isLastUsed) : null;
    if (this.handoverFile === undefined) this.handoverFile = null;
  }

  private splitToFileNameAndFolderPath(virtualFilePath: string): { fileName: string, folderPath: string } {
    if (!virtualFilePath) {
      return null;
    }

    const arr = virtualFilePath.split('/');
    const fileName = arr.pop();
    const folderPath = arr.join('/');
    return {
      fileName: fileName,
      folderPath: folderPath,
    };
  }

  get getCPFileName(): string {
    const vIDAJobId = this.job.jobCode
    const customerName = this.job.customerName.trim().toUpperCase()
    const jobName = this.jobInfor && this.jobInfor.JobName.trim().toUpperCase()
    const fileNameFormatted = this.controlPointService.fileNameFormatter(`CP3 (${vIDAJobId})-${customerName}-${jobName}`);
    return fileNameFormatted + '.xlsx'
  }

  get getCallSheetFileName(): string {
    const customerName = this.job.customerName.trim().toUpperCase();
    const jobName = this.job.jobName.trim().toUpperCase();
    const wellName = this.job.wellName.trim().toUpperCase();
    const fileNameFormatted = this.controlPointService.fileNameFormatter(`Call Sheet (${this.job.jobCode})-${customerName}-${wellName} - ${jobName}.docx`);
    return fileNameFormatted;
  }
}
