import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LinerCirculationMethods, VersaFlexOptions } from 'libs/constants';
import { UnitType } from 'libs/constants/unit-of-measure.constants';
import { environment } from 'libs/environment';
import { PumpScheduleService } from '../../../pump-schedule/services';
import { PumpScheduleStateManager } from '../../state/schedule-state-manager';

@Component({
    selector: 'liner',
    templateUrl: './liner.component.html',
    styleUrls: ['./liner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinerComponent implements OnInit {

    public readonly UnitType = UnitType;

    public LinerCirculationMethods;

    public readonly VersaFlexOptions = VersaFlexOptions;

    @Input()
    public scheduleState: PumpScheduleStateManager;

    @Input()
    public isLinerParamsRequired: boolean;

    public get pumpScheduleForm(): UntypedFormGroup {

        return this.scheduleState.form;
    }

    public get linerJobGuidelineLink(): string {

        return environment.linerJobGuidelineLink;
    }

    public get linerJobVersaFlexGuidelineLink(): string {

        return environment.linerJobVersaFlexGuidelineLink;
    }

    constructor(
        private pumpSchedule: PumpScheduleService,
        private cd: ChangeDetectorRef
    ){}

    ngOnInit(){
        this.pumpSchedule.getLinerCirculation().subscribe(res => {
            this.LinerCirculationMethods = res.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            this.cd.detectChanges();
        })
    }
}
