<div class="flx-ctn-row">
 

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20">
    <job-totals [listPumpScheduleStageStateManager$]="listPumpScheduleStageStateManager$" [scheduleState]="scheduleState" [hasScope3access]="hasScope3access"></job-totals>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Spacer Mix Method</div>
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Method</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle">
                  <div>
                    <p-dropdown formControlName="spacerMixMethod" [options]="spacerMixMethods"
                      [lockableElement]="'pumpSchedule_spacerMixMethod'"></p-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Shoe Track - {{namePumpSchedule}}</div>
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Length</th>
                <th *ngIf="isScheduleEditView">Actual Length</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle">
                  <div class="input-unit" [lockableElement]="'ShoeTrack_shoeTrackLength'">
                    <input-unit formControlName="shoeTrackLength" unit={{UnitType.Distance}}></input-unit>
                  </div>
                  <div class="custom-unit" dUnitName={{UnitType.Distance}}></div>
                </td>
                <td *ngIf="isScheduleEditView" class="vertical-align-middle">
                  <div class="custom-unit display-center" dUnitName={{UnitType.Distance}}></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Volume</th>
                <th *ngIf="isScheduleEditView">Actual Volume</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="vertical-align-middle" [lockableElement]="'ShoeTrack_shoeTrackVolume'">
                  <div class="input-unit">
                    <input-unit formControlName="shoeTrackVolume" unit={{UnitType.LargeVolume}}></input-unit>
                  </div>
                  <div class="custom-unit" dUnitName={{UnitType.LargeVolume}}></div>
                </td>
                <td *ngIf="isScheduleEditView" class="vertical-align-middle">
                  <div class="custom-unit display-center" dUnitName={{UnitType.LargeVolume}}></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20" [formGroup]="pumpScheduleForm">
    <div class="panel panel-custom no-padding custom-panel">
      <div class="panel-heading">Schedule - {{namePumpSchedule}}</div>
      <div class="panel-body">
        <div class="ui-table">
          <table>
            <thead>
              <tr>
                <th>Scheduled Shutdown (hh:mm) {{ (isLinerParamsRequired$ | async) ? '*' : '' }}</th>
                <th>Target Safety Factor (hh:mm)</th>
                <th class="p-b-0">
                  <p class="info-batch-mixing-time">Batch Mixing Time (hh:mm)</p>
                  <div class="more-info-icon tip-batch-mixing-time">
                    <div class="more-info-icon-target"></div>
                    <i class="fa fa-info-circle icon-info" (click)="infoBatchMixingTime.toggle($event)"></i>
                    <p-overlayPanel #infoBatchMixingTime [dismissable]="true" [showCloseIcon]="true"
                      styleClass="cpoint-overlay-panel">
                      Batch Mixing Time is for Display and Reporting purposes only. Not used in calculations.
                    </p-overlayPanel>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="input-with-100" [class.exception-disable-item]="enablingCPStates$ | async">
                    <p-inputMask type="text"
                      formControlName="scheduledShutdown"
                      styleClass="form-control"
                      mask="99:a9"
                      characterPattern="[0-5]"
                      placeholder="hh:mm"
                      [lockableElement]="'pumpSchedule_shutdown'">
                    </p-inputMask>
                  </div>
                </td>
                <td>
                  <div class="input-with-100" [class.exception-disable-item]="enablingCPStates$ |async">
                    <p-inputMask type="text" 
                      formControlName="targetSafetyFactor" 
                      styleClass="form-control" 
                      mask="99:a9"
                      characterPattern="[0-5]"
                      placeholder="hh:mm" 
                      [lockableElement]="'pumpSchedule_targetSafetyFactor'">
                    </p-inputMask>
                  </div>
                </td>
                <td>
                  <div class="input-with-100" [class.exception-disable-item]="enablingCPStates$ | async">
                    <p-inputMask type="text"
                      formControlName="batchMixingTime" 
                      styleClass="form-control" 
                      mask="99:a9"
                      characterPattern="[0-5]"
                      placeholder="hh:mm" 
                      [lockableElement]="'pumpSchedule_batchMixingTime'">
                    </p-inputMask>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="isLinerParamsVisible$ | async"
    class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20">
    <liner [scheduleState]="scheduleState" [isLinerParamsRequired]="isLinerParamsRequired"></liner>
  </div>

  <div class="flx-itm-12-12 flx-itm-xs-6-12 p-t-20 p-r-20">
    <div class="panel panel-custom no-padding">
      <div class="panel-heading">Job Placement Time - {{namePumpSchedule}}</div>
      <div class="panel-body">
        <p-table [value]="scheduleState.cementStagesStatesWithoutShutDown$ | async">
          <ng-template pTemplate="header">
            <tr>
              <th class="width-column-num">Stage</th>
              <th class="width-column-13">Slurry Type</th>
              <th class="width-column-19">Tested TT (hh:mm)*</th>
              <th class="width-column-13">JPT (hh:mm)</th>
              <th *ngIf="isLinerParamsVisible$ | async" class="width-column-19">
                <span class="info-min-tt">Stage Specific Shutdowns (hh:mm)</span>
                <div class="more-info-icon tip-specific-shutdown">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle icon-info"
                    (click)="onSelectedOverlayPanel($event, infoSpecificShutdown, infoActualSafety)"></i>
                  <p-overlayPanel #infoSpecificShutdown [dismissable]="true" [showCloseIcon]="true"
                    styleClass="cpoint-overlay-panel">
                    <div>Stage Specific Shutdowns apply only to a single stage.</div>
                    <div>Consider using for the Lead Cement, and for the Tail Cement if there is a possibility of contamination.</div>
                  </p-overlayPanel>
                </div>
              </th>
              <th class="width-column-13">
                <span class="info-min-tt">Min TT (hh:mm)</span>
                <div class="more-info-icon tip-min-tt">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle icon-info"
                    (click)="onSelectedOverlayPanel($event, infoMinTT, infoActualSafety)"></i>
                  <p-overlayPanel #infoMinTT [dismissable]="true" [showCloseIcon]="true"
                    styleClass="cpoint-overlay-panel">
                    Min TT = JPT + Scheduled Shutdown + Target Safety Factor.
                  </p-overlayPanel>
                </div>
              </th>
              <th class="width-column-13">
                <p class="info-actual-safety">Actual Safety (hh:mm)</p>
                <div class="more-info-icon tip-actual-safety">
                  <div class="more-info-icon-target"></div>
                  <i class="fa fa-info-circle icon-info"
                    (click)="onSelectedOverlayPanel($event, infoActualSafety, infoMinTT)"></i>
                  <p-overlayPanel #infoActualSafety [dismissable]="true" [showCloseIcon]="true"
                    styleClass="cpoint-overlay-panel">
                    Actual Safety = Tested TT - JPT.
                  </p-overlayPanel>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cementStage>
            <tr [formGroup]="cementStage.placementTimeForm">
              <td class="width-column-num vertical-align-middle bold" style="color: white"
                [style.backgroundColor]="cementStage.color$ | async">
                {{ cementStage.number$ | async }}
              </td>
              <td class="width-column-13 vertical-align-middle">{{ cementStage.slurryType$ | async }}</td>
              <td class="width-column-19">
                <div class="input-with-tooltips" [class.exception-disable-item]="enablingCPStates$ | async">
                  <p-inputMask type="text"
                    formControlName="thickeningTime"
                    styleClass="form-control" 
                    [class.ng-invalid]="(cementStage.isThickeningTimeInvalid$ | async) 
                                        && (submitStatus== true || completeStatus == true)"
                    [class.warning]="!(cementStage.thickeningTimeError$ | async) && cementStage.thickeningTimeWarning$ | async"
                    mask="99:a9"
                    characterPattern="[0-5]"
                    [autoClear]="false"
                    placeholder="hh:mm"
                    [lockableElement]="'pumpSchedule_thickeningTime'"
                    [class.exception-disable-item]="!(isThickeningTimeDisable$ | async)">
                  </p-inputMask>

                  <more-info-tooltip *ngIf="cementStage.thickeningTimeError$ | async" class="more-info-icon warning">
                    <i icon class="fa fa-info-circle"></i>
                    <p message>
                      WARNING: Tested Thickening Time should not be equal to or less than Job Placement Time.
                    </p>
                  </more-info-tooltip>

                  <more-info-tooltip *ngIf="!(cementStage.thickeningTimeError$ | async) && cementStage.thickeningTimeWarning$ | async" class="more-info-icon warning">
                    <i icon class="fa fa fa-exclamation-triangle"></i>
                    <p message>
                      WARNING: Tested Thickening Time should not be less than Minimum Thickening Time.
                    </p>
                  </more-info-tooltip>
                </div>

                <error-display formControlName="thickeningTime"></error-display>
              </td>
              <td class="width-column-13 vertical-align-middle">{{ cementStage.placementTime$ | async }}</td>
              <td *ngIf="isLinerParamsVisible$ | async" class="width-column-19">
                <div class="input-with-tooltips" [class.exception-disable-item]="enablingCPStates$ | async">
                  <p-inputMask type="text"
                    formControlName="specificShutdownTime"
                    styleClass="form-control"
                    mask="99:a9"
                    characterPattern="[0-5]"
                    [autoClear]="false"
                    placeholder="hh:mm"
                    [lockableElement]="'pumpSchedule_specificShutdownTime'">
                  </p-inputMask>
                </div>
              </td>
              <td class="width-column-13 vertical-align-middle">{{ cementStage.minThickeningTime$ | async }}</td>
              <td class="width-column-13 vertical-align-middle">{{ cementStage.actualSafety$ | async }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
